import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { SentryService } from '@box-core/services';
import { Observable, of } from 'rxjs';
import { setRedirectPrerenderMetaElements } from '@box/utils';

const REDIRECT_PATH = '/';

@Injectable({ providedIn: 'root' })
export class ShopRedirectGuard implements CanActivate {
  /*
  This is a redirection guard to handle the old Shop Page url
  redirection after the SEO changes.
  The new Shop Page URL is /delivery/:locationKey/:vanityUrl
  */
  constructor(private router: Router, private sentryService: SentryService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    this.set301RedirectRule();
    void this.router.navigate([REDIRECT_PATH]);
    this.sentryService.captureException(new Error('Find Shop by old vanity visit'), {
      domain: 'Delivery',
      domainDetails: 'Shop Redirect Guard',
      severity: 'info'
    });
    return of(true);
  }

  private set301RedirectRule(): void {
    const url = window.location.origin;
    setRedirectPrerenderMetaElements(url);
  }
}
