import { Injectable } from '@angular/core';
import { Observable, of, map, from, catchError } from 'rxjs';
import { environment } from '@box-env/environment';
import { SentryService } from '.';
import { LanguageService } from '@box-core/services/language.service';
import { Loader as MapsApiLoader } from '@googlemaps/js-api-loader';
import { Platform } from '@angular/cdk/platform';

@Injectable({ providedIn: 'root' })
export class MapsService {
  private apiLoaded = false;
  private mapsApiLoader: MapsApiLoader;

  constructor(
    private sentryService: SentryService,
    private languageService: LanguageService,
    private platform: Platform
  ) {
    this.initializeMapsApiLoader();
  }

  private initializeMapsApiLoader(): void {
    if (!this.platform.isBrowser) return;
    const langCode = this.languageService.getLanguageCode();
    this.mapsApiLoader = new MapsApiLoader({
      apiKey: environment.google.MAPS_API_KEY,
      version: 'weekly', // Use the latest stable version of the API
      libraries: ['places'], // Add additional libraries if needed
      language: langCode,
      region: 'gr'
    });
  }

  public loadAPI(): Observable<boolean> {
    // If not in the browser, return false
    if (!this.platform.isBrowser) {
      return of(false);
    }

    // If already loaded, return true
    if (this.apiLoaded) {
      return of(true);
    }

    return from(this.mapsApiLoader.load()).pipe(
      map(() => {
        this.apiLoaded = true;
        return true;
      }),
      catchError((error: any) => {
        this.sentryService.captureException(error, {
          domain: 'Google Maps',
          domainDetails: 'Google Maps API JS Error',
          severity: 'error'
        });
        return of(false);
      })
    );
  }
}
