import { AfterViewInit, Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService, CosmoteIDService, UserService, AnalyticsService } from '@box-core/services';
import { User } from '@box-types';
import { Platform } from '@angular/cdk/platform';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import { getUserFullName, getInitials } from '@box/utils';

const MOBILE_BREAKPOINT = '(max-width: 768px)';

@Component({
  selector: 'box-cosmote-id-button',
  templateUrl: './box-cosmote-id-button.component.html',
  styleUrls: ['./box-cosmote-id-button.component.scss']
})
export class BoxCosmoteIdButtonComponent implements OnInit, AfterViewInit, OnDestroy {
  public disabled = true;
  public isSmallScreen: boolean;
  public buttonText: string;
  public authenticated: boolean;

  private user: User;
  private breakPointObserverSubscription: Subscription;

  constructor(
    private platform: Platform,
    private cosmoteIDService: CosmoteIDService,
    private authenticationService: AuthenticationService,
    private breakpointObserver: BreakpointObserver,
    private userService: UserService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.authenticated = this.authenticationService.isAuthenticated;
    this.user = this.userService.getUser();
    this.buttonText = this.getButtonText();
    this.setBreakPointObserverSubscription();
  }

  ngOnDestroy() {
    this.breakPointObserverSubscription?.unsubscribe();
  }

  ngAfterViewInit(): void {
    if (this.platform.isBrowser) {
      this.cosmoteIDService.setCosmoteIDSSOLogoutListener();
      this.setCosmoteIDLogin();
    }
  }

  private setBreakPointObserverSubscription(): void {
    this.breakPointObserverSubscription = this.breakpointObserver.observe([MOBILE_BREAKPOINT]).subscribe(() => {
      this.isSmallScreen = this.breakpointObserver.isMatched(MOBILE_BREAKPOINT);
    });
  }

  public onConnectClick(): void {
    if (this.authenticated) return;
    this.analyticsService.addGACustomEvent('click_login', { source: 'header' });
  }

  private getButtonText(): string {
    const userFullName = getUserFullName(this.user);
    const userInitials = getInitials(userFullName).slice(0, 2);
    return this.authenticated ? `${userInitials}` : 'connect_register';
  }

  private setCosmoteIDLogin(): Promise<void> {
    if (!this.cosmoteIDService.scriptLoaded) {
      return this.cosmoteIDService.loadCosmoteIDScript().finally(() => {
        this.disabled = false;
      });
    }
    return this.cosmoteIDService.setCosmoteIDWidget().then(() => {
      this.disabled = false;
    });
  }
}
