import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { environment } from '@box-env/environment';
import { CosmoteIDDataParams } from '@box-types';
import { goToLogin, storageGet, isPrerenderBrowser } from '@box/utils';
import { AuthenticationService } from '@box-core/services/authentication.service';
import { SentryService } from '@box-core/services/sentry.service';

@Injectable({ providedIn: 'root' })
export class CosmoteIDService {
  private renderer: Renderer2;
  private ROOT_URL: string = environment.application.ROOT_URL;
  private COSMOTE_ID_ACCOUNT_URL: string = environment.cosmote.ACCOUNT_URL;
  private COSMOTE_ID_CLIENT_ID: string = environment.cosmote.cosmoteID.CLIENT_ID;
  private COSMOTE_ID_CHANNEL_ID: string = environment.cosmote.cosmoteID.CHANNEL_ID;
  private COSMOTE_ID_DOMAIN: string = environment.cosmote.cosmoteID.DOMAIN;
  private COSMOTE_ID_SCOPE: string = environment.cosmote.cosmoteID.SCOPE;

  public scriptLoaded: boolean;

  private returnURL: string;

  constructor(
    private rendererFactory: RendererFactory2,
    private authenticationService: AuthenticationService,
    private sentryService: SentryService
  ) {
    // We can't inject Renderer2 in a Service. This is a workaround until we find a better solution
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.returnURL = storageGet('Box:returnURL', window.localStorage);
  }

  public setReturnURL(url: string): void {
    this.returnURL = url;
    window.localStorage.setItem('Box:returnURL', this.returnURL);
  }

  public getReturnUrl(): string {
    return this.returnURL ?? '';
  }

  public loadScript(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const bodyEl: HTMLBodyElement = window.document.getElementsByTagName('body')[0];
      const scriptEl: HTMLScriptElement = this.getScriptElement();
      scriptEl.onload = () => {
        this.scriptLoaded = true;
        resolve();
      };
      scriptEl.onerror = () => {
        this.scriptLoaded = false;
        reject();
      };
      bodyEl.appendChild(scriptEl);
    });
  }

  public updateScriptDataParams(): void {
    const scriptElement: HTMLScriptElement = window.document.querySelector('#md-login');
    const dataParams: string = this.getDataParams();
    this.renderer.setAttribute(scriptElement, 'data-params', dataParams);
  }

  public redirectToCosmoteId(): void {
    goToLogin();
  }

  private getDataParams(): string {
    const dataParams: CosmoteIDDataParams = {
      v2: true,
      dest: 'OAM',
      guid: this.authenticationService.GUID ?? '',
      qrCode: false,
      popupConsent: true,
      'alt-theme': 'third-party',
      scope: this.COSMOTE_ID_SCOPE,
      domain: this.COSMOTE_ID_DOMAIN,
      clientId: this.COSMOTE_ID_CLIENT_ID,
      loginUrl: this.ROOT_URL + '/login',
      logoutUrl: this.ROOT_URL + '/logout'
    };

    const queryData = Object.keys(dataParams)
      .map((key) => key + '=' + dataParams[key]) // eslint-disable-line
      .join('&');

    return queryData;
  }

  private getScriptElement(): HTMLScriptElement {
    const loginScript = this.renderer.createElement('script') as HTMLScriptElement;
    this.renderer.setAttribute(loginScript, 'id', 'md-login');
    this.renderer.setAttribute(loginScript, 'async', '');
    this.renderer.setAttribute(loginScript, 'src', this.COSMOTE_ID_ACCOUNT_URL + '/modalLogin/login.js');
    this.renderer.setAttribute(loginScript, 'data-chid', this.COSMOTE_ID_CHANNEL_ID);
    this.renderer.setAttribute(loginScript, 'data-modalstyle', 'simple');
    this.renderer.setAttribute(loginScript, 'data-provision-flow', 'register');
    this.renderer.setAttribute(loginScript, 'data-goto', this.ROOT_URL + '/login/redirect');
    this.renderer.setAttribute(loginScript, 'data-gotofail', this.ROOT_URL + '/login/redirect?failed=true');
    const dataParams: string = this.getDataParams();
    this.renderer.setAttribute(loginScript, 'data-params', dataParams);
    return loginScript;
  }

  public setCosmoteIDSSOLogoutListener(): void {
    const messageCallback = (event: MessageEvent) => {
      if (!this.authenticationService.isAuthenticated) return;
      if (event.data === '##cosmoteid-sso-logout##') {
        this.authenticationService.signOut();
        window.location.reload();
      }
    };

    window.addEventListener('message', messageCallback, false);
  }

  public loadCosmoteIDScript(): Promise<void> {
    return this.loadScript().catch((error: unknown) => {
      if (!isPrerenderBrowser(window)) {
        this.sentryService.captureException(error, {
          domain: 'Authentication',
          domainDetails: 'Cosmote ID Script loading',
          severity: 'error'
        });
      }
    });
  }

  public setCosmoteIDWidget(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      try {
        setTimeout(() => {
          const mdLoginElement = document.getElementById('md-login');
          const widget = (window as any).cosmoteId_parseInitialState(mdLoginElement); // eslint-disable-line
          (window as any).cosmoteId_SetModalLogin(widget); // eslint-disable-line
          resolve();
        });
      } catch (error: unknown) {
        if (!isPrerenderBrowser(window)) {
          this.sentryService.captureException(error, {
            domain: 'Authentication',
            domainDetails: 'Cosmote ID Widget Init',
            severity: 'error'
          });
        }
        reject();
      }
    });
  }
}
