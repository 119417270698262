import {
  DiscoverFilter,
  DiscoverFilteringOption,
  PromoCampaign,
  Shop,
  Tag,
  Brand,
  DiscoverFilterSearchOptions,
  PromoVisualOptions,
  FilterItemShopsExtraOptions,
  DiscoverSearchResponse,
  PromoCampaignType
} from '@box-types';

import { discoverDeliveryOption } from '@box-configs/discover-delivery.option';
import { filterPromoCampaignsByTypes } from '../promo-campaigns';
import { shopMatchesTag, shopMatchesTagId } from '../shops';
import { generateImageSrc } from '../media';
import { filterItemShops, intersectArrays } from '../core';
import { getMainViewTileCurrentPosition } from '../home';

export {
  getDiscoverFilterMultiplier,
  getDiscoverFilterSlugsFromQueryString,
  getDiscoverFilterMultiplierTag,
  generateDiscoverItemsRequestOptions,
  isFilterDishRelated,
  tagToDiscoverFilteringOption,
  filterShopsBasedOnDelivery,
  generateDiscoverFilteringOptions,
  shopMatchesDiscoverFilteringOptions,
  filterShopsBasedOnOptions,
  filterShopsByDiscoverFilters,
  filterHasShopResults,
  getDiscoverFilterMainViewPosition,
  normalizeDiscoverFiltersSearchResponse
};

function getDiscoverFilterMultiplier(filter: DiscoverFilter, promoCampaigns: PromoCampaign[]): number {
  const filterHasNoPromoCampaigns = !filter?.shopCampaigns?.length;
  if (filterHasNoPromoCampaigns) return;

  const promoCampaignsWithMultiplier = promoCampaigns.filter((promo) => !!promo.multiplier);
  if (!promoCampaignsWithMultiplier) return;

  const matchedPromoCampaign = promoCampaignsWithMultiplier.find((promo) =>
    filter?.shopCampaigns.find((filterPromo) => filterPromo === promo.name)
  );

  return matchedPromoCampaign?.multiplier;
}

function getDiscoverFilterMultiplierTag(discoverFilter: DiscoverFilter): PromoVisualOptions[] {
  if (!discoverFilter?.showBadge) return [];
  const tagOptions: PromoVisualOptions[] = [];

  const multiplier = discoverFilter.multiplier;
  if (!multiplier) return [];
  tagOptions.push({ text: `x${multiplier}` });

  return tagOptions;
}

function getDiscoverFilterSlugsFromQueryString(queryParams: URLSearchParams): string[] {
  const filtersQuery = queryParams.get('filters');
  return filtersQuery ? filtersQuery.split(',') : [];
}

function generateDiscoverItemsRequestOptions(
  selectedDiscoverFilters: DiscoverFilter[],
  selectedBrand: Brand,
  selectedFilteringOptions: DiscoverFilteringOption[],
  shops: Shop[]
): DiscoverFilterSearchOptions {
  const shopCollectionTypes: number[] = shops
    ? shops.filter((shop) => shop.collectionType).map((shop) => shop.collectionType)
    : [];

  const promoCampaigns: string[] = selectedDiscoverFilters
    .filter((discoverFilter) => discoverFilter.shopCampaigns?.length)
    .flatMap((discoverFilter) => discoverFilter.shopCampaigns);

  const offerTypes: string[] = selectedDiscoverFilters
    .filter((discoverFilter) => discoverFilter.offerKinds?.length)
    .flatMap((discoverFilter) => discoverFilter.offerKinds);

  const discoverFiltersTagIds: string[] = selectedDiscoverFilters
    .filter((filter) => filter.tagIds?.length)
    .flatMap((filter) => filter.tagIds);

  const groupings: string[] = selectedDiscoverFilters
    .filter((filter) => filter.groupings?.length)
    .flatMap((filter) => filter.groupings);

  const brand = selectedBrand?._id;
  const selectedFilteringOptionsTagIds = (selectedFilteringOptions ?? []).map((filter) => filter.slug);

  return {
    shops: shopCollectionTypes,
    brand,
    promoCampaigns,
    offerTypes,
    tags: [...discoverFiltersTagIds, ...selectedFilteringOptionsTagIds],
    groupings
  };
}

function isFilterDishRelated(discoverFilter: DiscoverFilter, promoCampaigns: PromoCampaign[]): boolean {
  if (!discoverFilter) return false;
  if (discoverFilter.offerKinds?.length) return true;
  if (discoverFilter.tagIds?.length) return true;
  if (discoverFilter.groupings?.length) return true;
  if (!discoverFilter.shopCampaigns?.length) return false;

  const filterPromoCampaigns = discoverFilter.shopCampaigns.reduce((filterPromos, campaignName) => {
    const matchedCampaign = promoCampaigns.find((campaign) => campaign.name === campaignName);
    if (matchedCampaign) filterPromos.push(matchedCampaign);
    return filterPromos;
  }, [] as PromoCampaign[]);
  if (!filterPromoCampaigns?.length) return false;

  const dishRelatedCampaignTypes: PromoCampaignType[] = [
    'product_promo',
    'offer_promo',
    'product_cuisine_promo',
    'collection_drink'
  ];
  const productPromoCampaigns = filterPromoCampaignsByTypes(filterPromoCampaigns, dishRelatedCampaignTypes);
  return Boolean(productPromoCampaigns?.length);
}

function tagToDiscoverFilteringOption(tag: Tag, shops: Shop[]): DiscoverFilteringOption {
  const shopWithTagExists = shops?.some((shop) => shopMatchesTag(shop, tag));
  return {
    slug: tag._id,
    label: tag.name,
    shortLabel: tag.name,
    icon: generateImageSrc(tag.image),
    disabled: !shopWithTagExists,
    quickFilter: false,
    type: 'dietary'
  };
}

function filterShopsBasedOnDelivery(shops: Shop[], deliveryOption: DiscoverFilteringOption): Shop[] {
  if (!shops?.length) return [];
  if (!deliveryOption) return shops;
  const takeaway = deliveryOption?.checked;
  if (takeaway) return shops.filter((shop) => shop.takeAway);
  return shops;
}

function generateDiscoverFilteringOptions(shops: Shop[], tags: Tag[]): DiscoverFilteringOption[] {
  if (!shops?.length) return [];
  const dietaryFilters = (tags ?? [])?.map((tag) => {
    return tagToDiscoverFilteringOption(tag, shops);
  });
  const takeAwayShopsExist = shops?.some((shop) => shop.takeAway);
  const deliveryFilteringOption = {
    ...discoverDeliveryOption,
    disabled: !takeAwayShopsExist
  };
  return [...dietaryFilters, deliveryFilteringOption];
}

function shopMatchesDiscoverFilteringOptions(shop: Shop, options: DiscoverFilteringOption[]): boolean {
  if (!shop) return false;
  if (!options?.length) return true;
  const optionsTagIds = options.map((option) => option.slug);
  return optionsTagIds.every((id) => shopMatchesTagId(shop, id));
}

function filterShopsBasedOnOptions(shops: Shop[], filteringOptions: DiscoverFilteringOption[]): Shop[] {
  if (!filteringOptions?.length) return shops;
  const dietaryFilters = filteringOptions.filter((option) => option.type === 'dietary');
  const dietaryFilteredShops = shops.filter((shop) => shopMatchesDiscoverFilteringOptions(shop, dietaryFilters));
  const deliveryFilter = filteringOptions.find((filter) => filter.type === 'delivery');
  return filterShopsBasedOnDelivery(dietaryFilteredShops, deliveryFilter);
}

function filterShopsByDiscoverFilters(
  shops: Shop[],
  filters: DiscoverFilter[],
  extraOptions?: FilterItemShopsExtraOptions
): Shop[] {
  if (!filters?.length) return shops;
  const shopGroupsPerFilter = filters.map((filter) => filterItemShops(filter, shops, extraOptions));
  return intersectArrays(shopGroupsPerFilter);
}

function filterHasShopResults(
  filter: DiscoverFilter,
  shops: Shop[],
  extraOptions?: FilterItemShopsExtraOptions
): boolean {
  if (!shops?.length) return false;
  return Boolean(filterItemShops(filter, shops, extraOptions).length);
}

function getDiscoverFilterMainViewPosition(discoverFilter: DiscoverFilter): number {
  const { mainViewDefaultPosition, mainViewSortingPerDay } = discoverFilter;
  const defaultPosition = mainViewDefaultPosition ?? 0;
  const mainViewPosition = getMainViewTileCurrentPosition(mainViewSortingPerDay);
  return mainViewPosition ?? defaultPosition;
}

function normalizeDiscoverFiltersSearchResponse(payload: DiscoverSearchResponse): DiscoverSearchResponse {
  const filteredShops = payload.shops.filter((shop) => shop.products?.length > 0 || shop.offers?.length > 0);
  return {
    shops: filteredShops,
    showOnlyShops: payload.showOnlyShops
  };
}
