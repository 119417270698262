/**
 * Each Merchant can set a defined number of Cuisines (by our business team) as Merchant Cuisines
 * One of them can be the Main Cuisine of the Shop, defining the Shop menu
 *
 * The rest of those Merchant Cuisines (Secondary Cuisines) are important for our suggestion but not
 * as important as the Main Cuisine
 *
 * On a defined clock timer, the BOX Server runs a procedure and adds the cuisineIds array on the shop
 * Those cuisines are all the possible cuisines found in the Shop's Menu offers or Products.
 * Those cuisines are less important to our suggestion
 * */

import { Cuisine, Shop } from '@box-types';

export {
  getShopMainCuisine,
  getShopSecondaryCuisines,
  shopMainCuisineMatchesCuisinesIds,
  shopMainCuisineMatchesCuisines,
  shopSecondaryCuisinesMatchesCuisinesIds,
  shopSecondaryCuisinesMatchesCuisines,
  shopMainOrSecondaryCuisinesMatchesCuisinesIds,
  shopMainOrSecondaryCuisinesMatchesCuisines,
  filterShopsByMainCuisines,
  filterShopsByMainOrSecondaryCuisines,
  decorateShopWithCuisines,
  getShopCuisinesText
};

function getShopMainCuisine(shop: Shop, cuisines: Cuisine[]): Cuisine {
  const cuisineIdsByMerchant = shop.cuisineIdsByMerchant;
  if (!cuisines?.length || !cuisineIdsByMerchant?.length) return;
  const mainCuisineIdByMerchant = cuisineIdsByMerchant.find((c) => c.main);
  if (!mainCuisineIdByMerchant) return;
  return cuisines.find((cuisine) => mainCuisineIdByMerchant.cuisineId === cuisine._id);
}

function getShopSecondaryCuisines(shop: Shop, cuisines: Cuisine[]): Cuisine[] {
  const cuisineIdsByMerchant = shop.cuisineIdsByMerchant;
  if (!cuisines?.length || !cuisineIdsByMerchant?.length) return [];
  const shopCuisinesIds = cuisineIdsByMerchant.filter((c) => c.main === false).map((c) => c.cuisineId);
  return cuisines.filter((cuisine) => shopCuisinesIds.includes(cuisine._id));
}

function shopMainCuisineMatchesCuisinesIds(shop: Shop, cuisinesIds: string[]): boolean {
  if (!cuisinesIds?.length) return false;
  const cuisineIdsByMerchant = shop.cuisineIdsByMerchant;
  if (!cuisineIdsByMerchant?.length) return false;
  const mainCuisine = cuisineIdsByMerchant.find((c) => c.main);
  if (!mainCuisine) return false;
  return cuisinesIds.includes(mainCuisine.cuisineId);
}

function shopMainCuisineMatchesCuisines(shop: Shop, cuisines: Cuisine[]): boolean {
  if (!cuisines?.length) return false;
  const cuisineIds = cuisines.map((cuisine) => cuisine._id);
  return shopMainCuisineMatchesCuisinesIds(shop, cuisineIds);
}

function shopSecondaryCuisinesMatchesCuisinesIds(shop: Shop, cuisinesIds: string[]): boolean {
  if (!cuisinesIds?.length) return false;
  const cuisineIdsByMerchant = shop.cuisineIdsByMerchant;
  if (!cuisineIdsByMerchant?.length) return false;
  const secondaryCuisineIds = shop.cuisineIdsByMerchant.filter((c) => !c.main);
  return secondaryCuisineIds.some((c) => cuisinesIds.includes(c.cuisineId));
}

function shopSecondaryCuisinesMatchesCuisines(shop: Shop, cuisines: Cuisine[]): boolean {
  if (!cuisines?.length) return false;
  const cuisineIds = cuisines.map((cuisine) => cuisine._id);
  return shopSecondaryCuisinesMatchesCuisinesIds(shop, cuisineIds);
}

function shopMainOrSecondaryCuisinesMatchesCuisinesIds(shop: Shop, cuisinesIds: string[]): boolean {
  if (!cuisinesIds?.length) return false;
  const cuisineIdsByMerchant = shop.cuisineIdsByMerchant;
  if (!cuisineIdsByMerchant?.length) return false;
  return cuisineIdsByMerchant.some((c) => cuisinesIds.includes(c.cuisineId));
}

function shopMainOrSecondaryCuisinesMatchesCuisines(shop: Shop, cuisines: Cuisine[]): boolean {
  if (!cuisines?.length) return false;
  const cuisinesIds = cuisines.map((cuisine) => cuisine._id);
  return shopMainOrSecondaryCuisinesMatchesCuisinesIds(shop, cuisinesIds);
}

function filterShopsByMainCuisines(shops: Shop[], cuisines: Cuisine[]): Shop[] {
  if (!shops?.length) return [];
  if (!cuisines?.length) return shops;
  return shops.filter((shop) => shopMainCuisineMatchesCuisines(shop, cuisines));
}

function filterShopsByMainOrSecondaryCuisines(shops: Shop[], cuisines: Cuisine[]): Shop[] {
  if (!shops?.length) return [];
  if (!cuisines?.length) return shops;
  return shops.filter((shop) => shopMainOrSecondaryCuisinesMatchesCuisines(shop, cuisines));
}

function decorateShopWithCuisines(shop: Shop, cuisines: Cuisine[]): Shop {
  const mainCuisine = getShopMainCuisine(shop, cuisines);
  const secondaryCuisines = getShopSecondaryCuisines(shop, cuisines);
  const mainCuisineText = getMainCuisineText(shop, mainCuisine, secondaryCuisines);
  const cuisinesText = getShopCuisinesText(mainCuisine, secondaryCuisines);
  return { ...shop, mainCuisine, secondaryCuisines, mainCuisineText, cuisinesText };
}

function getMainCuisineText(shop: Shop, mainCuisine: Cuisine, secondaryCuisines: Cuisine[]): string {
  let mainCuisineText: string;

  if (mainCuisine?.name) {
    mainCuisineText = mainCuisine.name;
  } else if (secondaryCuisines[0]?.name) {
    mainCuisineText = secondaryCuisines[0].name;
  } else {
    mainCuisineText = shop.cuisines?.split(', ')[0];
  }

  return mainCuisineText;
}

function getShopCuisinesText(mainCuisine: Cuisine, secondaryCuisines: Cuisine[]): string {
  const cuisinesNames: string[] = [];
  if (mainCuisine?.name) cuisinesNames.push(mainCuisine.name);

  if (secondaryCuisines?.length) {
    secondaryCuisines.slice(0, 2).map((secondaryCuisine) => {
      if (secondaryCuisine?.name) cuisinesNames.push(secondaryCuisine.name);
    });
  }
  return cuisinesNames.join(', ');
}
