import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, switchMap, catchError, finalize, mapTo } from 'rxjs';
import { environment } from '@box-env/environment';
import { Address, MapPoint, Shop, PromoBanner, APIResponse, APIError } from '@box-types';
import { map } from 'rxjs/operators';
import { UserService } from '@box-core/services/user.service';
import { Location } from '@angular/common';
import {
  circleContainsPoint,
  filterItemShops,
  filterEnabledItems,
  normalizePromoBanner,
  sortPromoBanners,
  normalizeWebAction,
  getCollectionCarouselImpressionGAConfig
} from '@box/utils';
import {
  CoreService,
  OrdersService,
  DialogService,
  SentryService,
  LoaderService,
  AnalyticsService,
  ShopsService,
  CouponsService
} from '.';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { PromoBannerDetailsDialogComponent } from '@box-core/components';
import { PromoBannerDetailsDialogResponse } from '@box-core/components/promo-banner-details-dialog/promo-banner-details-dialog.types';

@Injectable({ providedIn: 'root' })
export class PromoBannersService {
  private BOX_API: string = environment.application.API_URL;
  private readonly promoBannersSource: BehaviorSubject<PromoBanner[]> = new BehaviorSubject<PromoBanner[]>([]);
  public readonly promoBanners$: Observable<PromoBanner[]> = this.promoBannersSource.asObservable();

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private coreService: CoreService,
    private ordersService: OrdersService,
    private dialogService: DialogService,
    private activatedRoute: ActivatedRoute,
    private shopsService: ShopsService,
    private sentryService: SentryService,
    private router: Router,
    private loaderService: LoaderService,
    private analyticsService: AnalyticsService,
    private location: Location,
    private couponsService: CouponsService
  ) {}

  public fetchPromoBanner(slug: string): Observable<PromoBanner> {
    return this.http.get(`${this.BOX_API}/banners/${slug}`).pipe(
      map((response: APIResponse<{ promoBanner: PromoBanner }>) => {
        const promoBanner = response.payload?.promoBanner;
        if (!promoBanner) return; // @faropoulos. Please talk about this with the BE Team. Empty payload object on not found promo banner
        return normalizePromoBanner(promoBanner);
      })
    );
  }

  public fetchPromoBanners(): Observable<PromoBanner[]> {
    return this.http.get(`${this.BOX_API}/banners`).pipe(
      map((response: APIResponse<{ promoBanners: PromoBanner[] }>) => {
        const promoBanners = response.payload.promoBanners;
        return promoBanners.map((banner) => normalizePromoBanner(banner));
      })
    );
  }

  private getPromoBanner(slug: string, promoBanners: PromoBanner[]): Observable<PromoBanner> {
    const localPromoBanner = promoBanners.find((p) => p.slug === slug);
    const shops = this.shopsService.getShops();
    if (localPromoBanner) {
      const decoratedPromoBanner = this.decoratePromoBannerWithShops(localPromoBanner, shops);
      return of(decoratedPromoBanner);
    }

    this.loaderService.setState(true);
    return this.fetchPromoBanner(slug).pipe(
      finalize(() => this.loaderService.setState(false)),
      map((banner) => {
        if (!banner) return;
        return this.decoratePromoBannerWithShops(banner, shops);
      })
    );
  }

  public getPromoBanners(): PromoBanner[] {
    return this.promoBannersSource.getValue();
  }

  public setPromoBanners(promoBanners: PromoBanner[]): void {
    this.promoBannersSource.next(promoBanners);
  }

  public getLandingPromoBanner(promoBanners: PromoBanner[]): PromoBanner {
    const landingPromoBanners = promoBanners.filter((banner) => banner.isPromoted);
    if (landingPromoBanners.length === 0) return undefined;
    return landingPromoBanners[0];
  }

  public initializePromoBanners(promoBanners: PromoBanner[], address: Address, shops?: Shop[]): PromoBanner[] {
    const userSegments = this.userService.getUser()?.segments ?? [];
    const availableCoupons = this.couponsService.getAvailableCoupons();
    const enabledBanners = filterEnabledItems(promoBanners, userSegments, availableCoupons);
    const addressSpecificBanners = this.filterPromoBannersByAddress(address, enabledBanners);
    const decoratedBanners = addressSpecificBanners.map((banner) => this.decoratePromoBannerWithShops(banner, shops));
    const promoBannersToShow = decoratedBanners.filter(
      (promoBanner) =>
        !promoBanner.visibleOnlyWhenShopsFound ||
        (promoBanner.visibleOnlyWhenShopsFound && Boolean(promoBanner.relatedShops?.length))
    );
    return sortPromoBanners(promoBannersToShow);
  }

  private filterPromoBannersByAddress(address: Address, promoBanners: PromoBanner[]): PromoBanner[] {
    const point: MapPoint = { lat: address?.latitude, lng: address?.longitude };
    return promoBanners.filter((pb) => !pb.areas?.length || pb.areas.some((area) => circleContainsPoint(area, point)));
  }

  private decoratePromoBannerWithShops(promoBanner: PromoBanner, shops: Shop[]): PromoBanner {
    const cuisines = this.coreService.cuisines.getValue();
    const chains = this.coreService.chains.getValue();
    const orders = this.ordersService.getOrderHistory();
    const openShops = shops.filter((s) => s.operatingState === 'OPEN');
    const relatedShops = filterItemShops(promoBanner, openShops, { cuisines, orders, chains });
    return { ...promoBanner, relatedShops };
  }

  public openPromoBannerDetailsDialog(
    promoBanner: PromoBanner,
    promoBanners?: PromoBanner[]
  ): MatDialogRef<PromoBannerDetailsDialogComponent> {
    this.location.replaceState('/home', 'promo=' + promoBanner.slug);
    const dialogConfig: MatDialogConfig = { panelClass: 'box-dialog', data: { promoBanner } };
    const dialogRef = this.dialogService.openDialog(PromoBannerDetailsDialogComponent, dialogConfig);
    dialogRef.afterOpened().subscribe(() => this.triggerAnalyticsEvent(promoBanner, promoBanners));
    dialogRef.afterClosed().subscribe((data: PromoBannerDetailsDialogResponse) => {
      this.location.replaceState('/home');
      if (data?.triggerAction) this.triggerPromoBannerAction(promoBanner);
    });
    return dialogRef;
  }

  public openPromoBannerDetailsDialogCheck$(): Observable<null> {
    const promoBanners = this.getPromoBanners();
    const slug = this.activatedRoute.snapshot.queryParams.promo as string;
    if (!slug) return of(null);
    return this.getPromoBanner(slug, promoBanners).pipe(
      switchMap((promoBanner) => {
        if (promoBanner) {
          return this.openPromoBannerDetailsDialog(promoBanner).afterClosed().pipe(mapTo(null));
        }
        return of(null);
      }),
      catchError((error: APIError) => {
        this.sentryService.captureException(error, {
          domain: 'Promo Banners',
          domainDetails: 'Promo Banners Component Service - Get Promo Banner',
          severity: 'error'
        });
        return of(null);
      })
    );
  }

  public triggerPromoBannerAction(promoBanner: PromoBanner): void {
    const webAction = promoBanner?.webAction;
    if (!webAction) return void this.router.navigateByUrl('/discover');
    const navigationUrl = normalizeWebAction(webAction);
    return void this.router.navigateByUrl(navigationUrl);
  }

  private triggerAnalyticsEvent(promoBanner: PromoBanner, promoBanners: PromoBanner[]): void {
    const gaConfig = getCollectionCarouselImpressionGAConfig(
      'campaign modal',
      'campaign modal',
      promoBanners,
      promoBanner
    );
    this.analyticsService.addGAEcommerceEvent('view_promotion', gaConfig);
  }
}
