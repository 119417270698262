import { Shop, Timeslot, DaasAvailability, ShopOperatingStateType, GetTextByKeyType, Address } from '@box-types';
import { getShopClosedText, getShopOperatingState } from './shop-operating-state.utils';

export {
  filterDaasShops,
  shouldFetchDaasAvailability,
  isDaasAvailabilityUpdated,
  isDaasAvailabilityScheduled,
  daasAvailabilityHasNoTimeslots,
  getDaasShopOperatingState,
  decorateShopWithDaasOperatingState
};

function filterDaasShops(shops: Shop[]): Shop[] {
  if (!shops?.length) return [];
  return shops.filter((shop) => shop.daas);
}

function shouldFetchDaasAvailability(shop: Shop, address: Address, selectedTimeslot?: Timeslot): boolean {
  if (!shop) return false;
  const isShopOpen = shop.operatingState === 'OPEN';
  const asap = !selectedTimeslot;
  const addressExists = Boolean(address?.latitude && address?.longitude);
  const hasDelivery = shop.delivery;
  return shop.daas && asap && !shop.isSuperMarket && isShopOpen && addressExists && hasDelivery;
}

function isDaasAvailabilityUpdated(previousAvailability: DaasAvailability, newAvailability: DaasAvailability): boolean {
  const previousAvailabilitySerialized = JSON.stringify(previousAvailability);
  const newAvailabilitySerialized = JSON.stringify(newAvailability);
  return previousAvailabilitySerialized !== newAvailabilitySerialized;
}

function isDaasAvailabilityScheduled(availability: DaasAvailability): boolean {
  if (!availability) return;
  return Boolean(availability.firstAvailableTimeslot);
}

function daasAvailabilityHasNoTimeslots(availability: DaasAvailability): boolean {
  if (!availability) return;
  // an estimatedDelay of 0 doesn't mean there are no timeslots available
  const { estimatedDelay, firstAvailableTimeslot } = availability;
  return estimatedDelay === null && !firstAvailableTimeslot;
}

function getDaasShopOperatingState(
  shop: Shop,
  availability: DaasAvailability,
  timeslot: Timeslot
): ShopOperatingStateType {
  /* Since a DAAS Shop can have no available timeslots for delivery, even if it is in working hours, we do
  need a new function for the operatingState of the shop. Due to the current architecture, we are forced to
  change the operating state manually if that case happens */
  const operatingState = getShopOperatingState(shop, timeslot);
  if (operatingState !== 'OPEN') return operatingState;
  const hasNoTimeslots = availability && daasAvailabilityHasNoTimeslots(availability);
  if (hasNoTimeslots) return 'CLOSED';
  return operatingState;
}

function decorateShopWithDaasOperatingState(
  shop: Shop,
  daasAvailability: DaasAvailability,
  translateFn: GetTextByKeyType,
  timeslot: Timeslot
): Shop {
  /* Since we are using the getDaasShopOperatingState, we need to generate the disabledText again.
  That behavior is only needed in the Shop Page so far due to the current DaaS architecture. */
  const operatingState = getDaasShopOperatingState(shop, daasAvailability, timeslot);
  const disabledText = getShopClosedText({ ...shop, operatingState }, translateFn);
  return { ...shop, operatingState, disabledText };
}
