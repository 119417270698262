import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService, SentryService } from '@box-core/services';
import { isPrerenderBrowser } from '@box/utils';

@Injectable()
export class CosmoteIDInterceptor implements HttpInterceptor {
  private throttleTime = 5000; // 5 seconds
  private validationPaused: boolean;

  constructor(
    private router: Router,
    private sentryService: SentryService,
    private authenticationService: AuthenticationService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const COSMOTE_ACCESS_TOKEN = this.authenticationService.COSMOTE_ACCESS_TOKEN;
    const isValidationTokenRequest: boolean = request.url.includes('cosmoteid/v2/token/validate');
    if (!COSMOTE_ACCESS_TOKEN || isValidationTokenRequest || this.validationPaused) return next.handle(request);
    this.validationPaused = true;

    return this.authenticationService.validateCosmoteToken(COSMOTE_ACCESS_TOKEN).pipe(
      catchError(() => {
        return this.authenticationService
          .refreshAccessToken()
          .pipe(tap((refreshResponse) => this.authenticationService.setCosmoteTokens(refreshResponse)));
      }),
      tap(() => setTimeout(() => (this.validationPaused = false), this.throttleTime)),
      catchError((error: HttpErrorResponse) => {
        if (!isPrerenderBrowser(window)) {
          this.sentryService.captureException(error, {
            domain: 'Authentication',
            domainDetails: 'Cosmote ID Token Validation',
            severity: 'error'
          });
        }
        this.authenticationService.signOut();
        void this.router.navigate(['/']);
        return throwError(() => error);
      }),
      switchMap(() => next.handle(request))
    );
  }
}
